import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Container from '../components/container'

import style from './legal.module.css'

const TermsPage = () => {
  const data = useStaticQuery(graphql`
    query TermsQuery {
      markdownRemark(frontmatter: {path: {eq: "/terms/" }}) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
        }
      }
    }
  `)

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return(
    <Layout>
      <SEO
        title='Terms of Service (TOS)'
        description='Terms of Service (TOS) for Tweeker. Read more about how Tweeker does ab and multivariate testing.'
        canonical='https://tweeker.io/terms/'
      />
      <Header  />
      <Container>
        <h2 className={style.title}>{frontmatter.title}</h2>
        <p className={style.date}>Effective Date: {frontmatter.date}</p>
        <div
          className={style.container}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Container>
    </Layout>
  )
}

export default TermsPage